<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-4">
					<b>Rango de Fecha </b>
					<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
					<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
				</div>
				<div class="col-md-8 text-right">
					<a-button class="btn btn-success" icon="reload" @click="initModule" />
				</div>
				<div class="col-md-12 pt10">
					<a-table :columns="columns" :dataSource="cfList" rowKey="id">
						<div slot="id" slot-scope="record">
							{{ record.id }}
						</div>
						<div slot="created_at" slot-scope="record">{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
						<div slot="details" slot-scope="record">
							{{ numeral(record.amount).format('$0,0.00') }}
						</div>
						<div slot="action" slot-scope="record">
							<a-tooltip placement="top" v-if="record.key">
								<template slot="title">
									<span>Comprobante</span>
								</template>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only" @click="getSecureURL(record.id)">
									<a-icon type="file" />
								</button>
							</a-tooltip>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalView" title="Comprobante" :closable="false" width="80%" v-if="modalView">
			<template slot="footer">
				<a-button key="back" @click="closeModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<fileViewerComponent :fileURL="base64" />
			</div>
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { adminsList } from '@/constants'
import numeral from 'numeral'
import fileViewerComponent from '@/components/fileViewer'

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'cashFlowView',
	components: {
		fileViewerComponent,
	},
	data() {
		return {
			modalView: false,
			base64: '',
			columns: [
				{
					title: 'ID',
					align: 'center',
					scopedSlots: { customRender: 'id' },
					width: '10%',
				},
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
					width: '30%',
				},
				{
					title: 'Monto',
					align: 'center',
					scopedSlots: { customRender: 'details' },
					width: '30%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '30%',
				},
			],
			dateFormat,
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('cashFlow', ['cfList', 'summaryData', 'modalFileVisible', 'filters', 'spinnerLoader', 'spinnerLoaderLabel']),
		actualUser() {
			return !!adminsList.find((e) => e.id == this.user.user_id)
		},
	},
	mounted() {
		if (!this.cfList.length) {
			this.initModule()
		}
	},
	methods: {
		moment,
		numeral,
		closeModal() {
			this.modalView = false
			setTimeout(() => {
				this.base64 = ''
			}, 1000)
		},
		initModule(date, dateString) {
			//
			let payload = {}

			if (!!dateString && dateString.length) {
				payload = {
					startDate: dateString[0],
					endDate: dateString[1],
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format(dateFormat),
					endDate: moment(this.filters.defaultDate[1]).format(dateFormat),
				}
			}
			this.$store.dispatch('cashFlow/GET_FLOW', payload)
		},
		getSecureURL(id) {
			this.$store.dispatch('cashFlow/GET_FILE_URL', id).then((response) => {
				this.modalView = true
				this.base64 = response.data.url
			})
		},
		onPrintCashflow(id) {
			this.$store.dispatch('cashFlow/TICKET_BY_CASHIER', id)
		},
		onResetFilter(key) {
			this.$store.dispatch('cashFlow/RESET_ONE_FILTER', key)
		},
	},
}
</script>